import { Fragment, useState, useEffect } from "react";
import Logo from '../../components/logo/Logo';
import NavBar from '../../components/NavBar/NavBar';
import HeaderSearch from '../../components/HeaderSearch/HeaderSearch';
import Btn from '../../components/Btn/Btn';
import MobileMenu from "../../components/NavBar/MobileMenu"
import MainSearch from "../../components/NavBar/MainSearch"
import { useSelector } from "react-redux";
import PharmaEdgeNavBar from "../../components/PharmaEdge/PharmaEdgeNavBar/PharmaEdgeNavBar";
import PharmaEdgeMobileMenu from "../../components/PharmaEdge/PharmaEdgeNavBar/PharmaEdgeMobileMenu";
import PharmaEdgeMainSearch from "../../components/PharmaEdge/PharmaEdgeNavBar/PharmaEdgeMainSearch";
import { FaRegUser } from "react-icons/fa";
import "./header.css";

const PharmaEdgeHeader = () => {
    const { user, userToken, loading, checkAuthLoading, isAuthenticated } =
        useSelector((state) => state.user);
    // const { token} = user;
    // console.log(user.username, token);

    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const [userMenuOpen, setUserMenuOpen] = useState(false);

    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const [searchbarShow, setSearchbarShow] = useState(false);
    const onSearchHandler = () => {
        setSearchbarShow(prev => !prev);
    }
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);

    useEffect(() => {
        const header = document.querySelector(".header-section");
        setHeaderTop(-1);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    const toggleUserMenu = () => {
        setUserMenuOpen((prev) => !prev);
    };
    return (
        <Fragment>
            <div className={`header-section header-transparent sticky-header section ${scroll > headerTop ? "is-sticky" : ""
                }`} >
                <div className="header-inner">
                    <div className="container position-relative">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-xl-2 col-auto order-0">
                                <Logo
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.svg`}
                                />
                            </div>
                            <div className="col-auto col-xl d-flex align-items-center justify-content-xl-center justify-content-end order-2 order-xl-1">
                                <div className="menu-column-area d-none d-xl-block position-static">
                                    <PharmaEdgeNavBar />
                                </div>
                                <div className="header-search-area ms-xl-7 ms-0">

                                    <HeaderSearch onClick={onSearchHandler} />
                                </div>

                                <div className="header-mobile-menu-toggle d-xl-none ms-sm-2">
                                    <button type="button" className="toggle" onClick={onCanvasHandler}>
                                        <i className="icon-top"></i>
                                        <i className="icon-middle"></i>
                                        <i className="icon-bottom"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="col-xl-2 col d-none d-sm-flex justify-content-end order-1 order-xl-2">
                                {user ? (
                                                                    <div className="user-menu">
                                                                        <FaRegUser
                                                                            size={25}
                                                                            onClick={toggleUserMenu}
                                                                            className="user-icon"
                                                                        />
                                                                        {userMenuOpen && (
                                                                            <ul className="user-dropdown">
                                                                                <li>
                                                                                    <a href="/my-profile">View Profile</a>
                                                                                </li>
                                                                                <li>
                                                                                    <p style={{color: "#33333"}}>AbC Coins: {user.abc_coins}</p> 
                                                                                </li>
                                                                                <li>
                                                                                    <a href="/my-orders">My Orders</a>
                                                                                </li>	 
                                                                                <li>
                                                                                    <a href="/my-orders">Scholarship</a>
                                                                                </li>
                                                                            </ul>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <Btn name="Signin/register" />
                                                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PharmaEdgeMobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
            <PharmaEdgeMainSearch show={searchbarShow} onClose={onSearchHandler} />
        </Fragment>
    )
}

export default PharmaEdgeHeader;